@import "styles/variables";

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: calc(
    100vh - 74px - map-get($spacing-sizes, "1") - map-get($spacing-sizes, "1")
  );
  margin: 0 auto;
  max-width: $max-text-width;
  padding: map-get($spacing-sizes, "1") map-get($spacing-sizes, "3");
  overflow: auto;
  width: calc(
    100% - map-get($spacing-sizes, "3") - map-get($spacing-sizes, "3")
  );
}

@media only screen and (min-width: $mobile-large) {
  .container {
    padding: map-get($spacing-sizes, "1");
    width: calc(
      100% - map-get($spacing-sizes, "1") - map-get($spacing-sizes, "1")
    );
  }
}
